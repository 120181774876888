import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import ServiceSearch from './ServiceSearch';
import './ServiceSearchHeader.scss';

const ServiceSearchHeader: React.FC<{ isHidden?: boolean }> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHidden, setIsHidden] = useState<boolean>(Boolean(props.isHidden));

  useEffect(() => {
    const checkVisibilityByScroll = () => {
      const homePageSearch = window.document.getElementById('fixlyHomePageSearch');
      if (!homePageSearch) return;
      const topDistance = homePageSearch.getBoundingClientRect().top;

      if (topDistance < 1) {
        setIsHidden(false);
      } else {
        setIsHidden(true);
      }
    };

    const parseMessage = (message: MessageEvent) => {
      if (message.origin === window.location.origin && message.data.type === 'toggle_header_search') {
        setIsHidden(message.data.isHidden);
      }
    };

    window.addEventListener('message', parseMessage);
    window.addEventListener('scroll', checkVisibilityByScroll);
    return () => {
      window.removeEventListener('scroll', checkVisibilityByScroll);
      window.removeEventListener('message', parseMessage);
    };
  }, []);

  useEffect(() => {
    setIsHidden(Boolean(props.isHidden));
  }, [props.isHidden]);

  const serviceSearchCN = classnames({
    serviceSearchHeader: true,
    serviceSearchHeader_expanded: isExpanded,
    serviceSearchHeader_hidden: isHidden,
  });
  const closeButtonCN = classnames({
    serviceSearchHeader__close: true,
  });
  return (
    <div className={serviceSearchCN}>
      <ServiceSearch onFocus={() => setIsExpanded(true)} onBlur={() => setIsExpanded(false)} inHeader />
      <button type="button" className={closeButtonCN} onClick={() => setIsExpanded(false)} aria-label={t`Zamknij`} />
    </div>
  );
};

export default ServiceSearchHeader;
